.section__Jobs-styledContent {
  text-align: left;
  color: #8892b0;
}

.section__Jobs-styledContent h4 {
  color: #ccd6f6;
  font-size: 22px;
  font-weight: 500;
}

.section__Jobs-styledContent h5 {
  font-size: 13px;
  letter-spacing: 0.05em;
  margin-bottom: 30px;
}

.section__Jobs-styledContent p {
  font-size: 16px;
  margin-bottom: 10px;
}

