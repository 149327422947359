.section__Jobs-buttonCompany {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  text-align: left;
  font-weight: 500;
  padding: 10px 20px;
  font-size: 14px;
  display: block;
  margin: 0;
  border-radius: 3px;
}

.section__Jobs-buttonCompany:hover,
.section__Jobs-buttonCompany:focus {
  background-color:rgba(0, 0, 0, 0.04);
}

.section__Jobs-buttonCompany:hover,
.section__Jobs-buttonCompany:active,
.section__Jobs-buttonCompany:focus {
  color: #2f57ef;
  outline: 0px;
}
