.section__Jobs-container {
  margin-top: 10px;
  max-width: 700px;
  position: relative;
}



.section__Jobs-container span.index1-chosen {
  transform: translateY(0px);
}
.section__Jobs-container span.index2-chosen {
  transform: translateY(42px);
}
.section__Jobs-container span.index3-chosen {
  transform: translateY(84px);
}

.section__Jobs-styledTabList {
  max-width: 430px;
}

.section__Jobs-styledTabList {
  padding: 0;
}
